<template>
  <div>
    <Modal :options="{width: '40vw',esc:false}">
      <form @submit.prevent="guardar_alianza">
        <div class="title">Alianza {{ alianza.nombre }}</div>
        <div class="body">
          <div class="row form-group">
            <label for="nombre" class="col-form-label col-sm-12 col-lg-3">Nombre <i title="Nombre que tendrá la alianza, normalmente el nombre de la empresa, por ejemplo: 'Fundación Dondé'" class="fa-regular fa-circle-question"></i></label>
            <div class="col-sm-12 col-lg-9"><input v-model="alianza.nombre" type="text" name="nombre" id="nombre" class="form-control"></div>
          </div>
          <div class="row form-group">
            <label for="codigo" class="col-form-label col-sm-12 col-lg-3">Código <i title="el código debe ser una palabra que identifique de forma única a la alianza, por ejemplo: 'fundaciondonde'" class="fa-regular fa-circle-question"></i></label>
            <div class="col-sm-12 col-lg-9"><input v-model="alianza.codigo" type="text" name="codigo" id="codigo" class="form-control"></div>
          </div>
          <div class="row form-group">
            <label for="" class="col-form-label col-sm-12 col-lg-3">Estatus</label>
            <div class="col-sm-12 col-lg-9"><select v-model="alianza.estatus" name="estatus" id="estatus" class="form-control">
              <option value="activo">Activo</option>
              <option value="inactivo">Inactivo</option>
            </select></div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-12 text-right"><button class="btn btn-secondary" type="button" @click="abrir_modal">Agregar usuario</button></div>
          </div>
          <div class="usuarios">
            <div class="usuario">
              <table>
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Email</th>
                    <th>Estatus</th>
                    <th>Opciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(usuario,index) in alianza.usuarios">
                    <td>{{ usuario.nombre }}</td>
                    <td><span :title="usuario.email" class="email">{{ usuario.email }}</span></td>
                    <td>{{ usuario.estatus }}</td>
                    <td>
                      <button class="btn btn-primary mr-2" type="button" @click="editar_usuario(usuario,index)"><i class="fa-regular fa-pen-to-square"></i></button>
                      <button class="btn btn-danger" type="button" @click="eliminar_usuario(index)"><i class="fa-solid fa-trash-can"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="row">
            <div class="col-sm-12 text-right">
              <button class="btn btn-primary mr-2">Guardar</button>
              <button class="btn btn-danger" type="button" @click="$emit('close')">Cancelar</button>
            </div>
          </div>
        </div>
      </form>
    </Modal>

    <Usuario v-if="show_modal" :usuario="usuario"  @close="cerrar_modal" @update="actualizar_usuario" />
  </div>
</template>

<script>
import Modal from '@/components/Modal';
import BPBSwitch from '@/components/Form/Switch';

import Usuario from './Info/Usuario';

import api from '@/apps/analyticpoint/api/comite/alianzas';

export default {
  components: {
    Modal,BPBSwitch,Usuario
  },
  props: {
    alianza_id: {
      type: String
    }
  },
  data() {
    return {
      alianza: {
        nombre: null,
        codigo: null,
        estatus: 'activo',
        usuarios: []
      },
      show_modal: false,
      usuario: {
        nombre: null,
        email: null,
        estatus: 'activo'
      },
      index: null
    }
  },
  mounted() {
    if (this.alianza_id)
      this.obtener_alianza()
  },
  methods: {
    async obtener_alianza() {
      try {
        this.alianza = (await api.obtener_alianza(this.alianza_id)).data;
      }catch(e) {
        this.$log.error(e);
        this.$helper.showAxiosError(e,'Error');
      }
    },
    async guardar_alianza() {
      try {
        let res = '';
        if (!this.alianza.id)
          res = (await api.crear_alianza(this.alianza)).data;
        else
          res = (await api.actualizar_alianza(this.alianza.id, this.alianza)).data;

        this.$log.info('res', res);
        this.$emit('close', true);
      }catch(e) {
        this.$log.error(e);
        this.$helper.showAxiosError(e,'Error');
      }
    },
    editar_usuario(usuario,index) {
      this.usuario = usuario;
      this.index = index;
      this.show_modal = true;
    },
    eliminar_usuario(index) {
      let usuarios = [];

      this.alianza.usuarios.forEach((usuario, index_gnr) => {
        if (index_gnr != index)
          usuarios.push(usuario);
      })

      this.alianza.usuarios = usuarios;
    },
    abrir_modal() {
      this.show_modal = true;
    },
    cerrar_modal(update) {
      this.usuario = {
        nombre: null,
        email: null,
        estatus: 'activo'
      };
      this.index = null;
      this.show_modal = false;

      if (update)
        this.obtener_alianza();
    },
    actualizar_usuario(usuario) {
      if (this.index == null) {
        this.alianza.usuarios.push(usuario);
      }

      for(let i=0; i<this.alianza.usuarios.length; i++) {
        if (i == this.index)
          this.alianza.usuarios[i] = usuario;
      }

      this.cerrar_modal();
    }
  },
  watch: {
    tipo_usuario(val, old_val) {
      if (val)
        this.obtener_usuarios_account();
    }
  }
}
</script>

<style lang="scss">
.usuarios {
  .usuario {
    table {
      width: 100%;

      thead {
        background-color: hsl(0, 0%, 30%);

        tr {
          th {
            padding: 5px 10px;
          }
        }
      }

      tbody {
        tr {
          td {
            padding: 5px 10px;

            span.email {
              max-width: 200px;
              display: inline-block;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}
</style>